import React, { useState, useContext, useEffect } from 'react';
import makeAnimated from 'react-select/animated';
import BaseSelect from "react-select";
import FixRequiredSelect from "../../helpers/FixRequiredSelect";
import axios from 'axios';

import { LoginContext } from '../../hooks/context/LoginContext';

import Cookies from 'universal-cookie';

import { MiReplaceAll, getApiUrl, axiosPost, encryptData, getDecodedCookie } from '../../helpers/helperApp';

import ButtonOutline from '../ui/ButtonOutline';


let auxOptions; 

function MiSelect(props){

    const cookies = new Cookies();
    // const CancelToken = axios.CancelToken;
    // const source = CancelToken.source();
    // const source2 = CancelToken.source();
    // const source3 = CancelToken.source();
    const { apiKey, confApp, appCryptCom } = useContext(LoginContext); 

    const { estadoForm, val, loading, id, name, label, placeholder, col, isClearable, isSearchable, dataType, data, data2, extradata, required, disabled, readOnly, change, modifyOptions, disablethisid, formulario, disableids, bbdd, selectinformes, btnfilubi, selectPlanificador, loadOnInit, appendPost, disableidsarray, btnFilCen, clickBtnFilCen, saveBtnFilCen, saveBackUpOptions, saveValSelect, selectDirectIfOneOption, setTxtMode, sendSqlDecrypt } = props;
    let { depends } = props;

    const [loadingSelect, saveLoadingSelect] = useState(false);
    const [options, saveOptions] = useState([]);
    const [filtrandoUbi, saveFiltrandoUbi] = useState();

    const animatedComponents = makeAnimated();

    
    if( depends == null || depends == "" ){
        depends = {control : 0}        
    }
    let customStyles;
    if(selectPlanificador){
        customStyles = {
            control: (base, state) => ({
                ...base,
                borderColor:'#ced4da',
                borderColor: state.isFocused ? '#80bdff': '#ced4da',
                outline: 0,
                boxShadow: state.isFocused ? '0 0 0 0.2rem rgb(0 123 255 / 25%)' : 'inherit',
                // background: readOnly === "readonly" || loading || estadoForm === "readonly" ? "#e9ecef" : "white",
                color: "#495057",
                borderTop: "none",
                borderLeft : "none",
                borderRight : "none",
                borderRadius: "0px",
                boxShadow: state.isHover ? "none" : "none"
              }),
              option: (provided, state) => ({
                ...provided,
                color: "#212529",
                // fontSize: "1.2em",
                // fontWeight: "bold"
              }),
              singleValue: (provided) => ({
                ...provided,
                color: "#495057",
                fontSize: "1.2em",
                fontWeight: "bold",
                textAlign: "center",
                width: "100%"
              }),
              menuPortal: (provided) => ({
                ...provided,            
                zIndex: 9999
              }) 
        }
    }else{        
        customStyles = {
            control: (base, state) => ({
                ...base,
                borderColor:'#ced4da',
                borderColor: state.isFocused ? '#80bdff': '#ced4da',
                outline: 0,
                boxShadow: state.isFocused ? '0 0 0 0.2rem rgb(0 123 255 / 25%)' : 'inherit',
                background: readOnly === "readonly" || loading || estadoForm === "readonly" ? "#e9ecef" : "white",
                color: "#495057",
                }),
            //   option: (provided, state) => ({
            //     ...provided,
            //     color: "#212529",
            //   }),
                singleValue: (provided) => ({
                ...provided,
                color: "#495057",
                }),
                menuPortal: (provided) => ({
                ...provided,            
                zIndex: 9999
                }) 
        }
    }

    useEffect( () => {
                saveLoadingSelect(true);
                                       
                getDataCbo(dataType, data);

                saveLoadingSelect(false); 
                
                return () =>{    
                    // Token cancelación axios al desmontar componentes
                    // source.cancel();
                    // source2.cancel();
                    // source3.cancel();
                } 

    },[loadingSelect, loading, formulario.id, formulario[depends.control], data, disableidsarray])


    const getDataCbo = async (fuente, opcion) => {

        if( loadOnInit === false ) return;
        
        let savedOptions = false;
        if( fuente === "codigo" ){
            if( typeof opcion === "string" ){
                if( opcion.includes("´") ){
                    const search = '´';
                    const replaceWith = '"';
                    opcion = opcion.split(search).join(replaceWith);
                }
                opcion = JSON.parse(opcion);
            }

            if( disableidsarray != null &&  disableidsarray != "" ){                  
                opcion.map((option) => {               
                    if( disableidsarray.includes(option.value) ){
                        option["isDisabled"] = true;
                    }
                })                
            }

            saveOptions(opcion);
            if(typeof saveBackUpOptions === 'function'){
                saveBackUpOptions(opcion);                
            }
        }else{            
            const data = new FormData();
            const validacion = apiKey();
            data.append("validacion", validacion);            
            
            if( bbdd ){
                bbdd = await encryptData(bbdd, appCryptCom);
                data.append('bd', bbdd);
            }
            const idCookie = await encryptData(cookies.get('id'), appCryptCom);
            data.append('ua', idCookie);
            const nivelCookie = await encryptData(cookies.get('nivel'), appCryptCom);
            data.append('na', nivelCookie);
            const idReg = await encryptData(formulario.id, appCryptCom);
            data.append('ra', idReg);
            if(sendSqlDecrypt){                
                const isCboVarInf = await encryptData('isCboVarInf', appCryptCom);
                data.append('isCboVarInf', isCboVarInf);
            }
            if(selectinformes){
                data.append('si', true);
            }
                        
            // Encriptamos el tag sql. 
            opcion = await encryptData(opcion, appCryptCom);
            data.append(fuente, opcion);

            if(btnfilubi){
                // if(opcion.includes("app_loc.location=")){
                    saveFiltrandoUbi(true)
                // }                 
            }

            let url = null;
            if( fuente == "filter" ){
                url = getApiUrl(window.location.origin)+'/filters/getFilter';
            }else if( fuente == "url" ){
                data.append("fromselect", true);
                url = getApiUrl(window.location.origin)+opcion;
            }else{
                url = getApiUrl(window.location.origin)+'/app/getDataCbo';
            }

            if(appendPost){
                appendPost.map(post =>{                    
                    data.append(post.key, post.value);
                })
            }

            const respuesta = await axios.post(url, data);
            // const respuesta = await axiosPost(url, data, source.token);
            
            // Datos añadidos manualmente en el json
            if( extradata ){                
                respuesta.data = respuesta.data.concat(extradata);
            }
            
            if(selectinformes){
                const iconos = {"0" : "fal fa-file-pdf", "1" : "fal fa-desktop", "2" : "fal fa-calendar-alt" , "3" : "fal fa-at"};
                respuesta.data.map((option) => {
                    let pastillaVariables = "";
                    let pastillaPlantillas = ""; 
                    if(option.variables != "[]" && option.variables != ""){
                        // Hace preguntas
                        pastillaVariables = <span className='fal fa-question-square' 
                                                style={{backgroundColor: "#007bff", color: "white", padding: "5px", borderRadius: "3px", marginRight : "3px"}}>
                                            </span>
                    }
                    if(option.total > 1){
                        // Informe con varios formatos de salida
                        pastillaPlantillas = <span className='fal fa-file-plus' 
                                                style={{backgroundColor: "#dc3545", color: "white", padding: "5px", borderRadius: "3px", marginRight : "3px"}}>
                                              </span>
                    }              
                    option.label =  <div>
                                        <span className={iconos[option.tipo]+' pr-2 '}></span>
                                        <span style={{marginRight: "5px"}}>{option.label}</span>
                                        {pastillaVariables}
                                        {pastillaPlantillas}
                                    </div>;
                    if(formulario.id == "" && option.sqlinf && option.sqlinf.includes("reg_actual")){
                        option["isDisabled"] = true;
                    }                    
                })
            }
    
            // Si está activada la opción para que desactive el option que tenga el mismo id que el del registro del formulario lo recorremos y le añadimos la opción de deshabilitarlo
            if( disablethisid ){
                respuesta.data.map((option) => {               
                    if(option.value == formulario.id){
                        option["isDisabled"] = true;
                    }
                })

                saveOptions(respuesta.data);
                if(typeof saveBackUpOptions === 'function'){
                    saveBackUpOptions(respuesta.data);
                }
                savedOptions = true;
            }

            // Si queremos desactivar algún id por el motivo que sea le pasamos un controller y función a la que llamar y que nos devuelva los ids que queremos desactivar. 
            // Ej.: /controller/funcion
            if( disableids != null &&  disableids != "" ){
                const data = new FormData();
                const validacion = apiKey();
                data.append("validacion", validacion);
                data.append("idreg", formulario.id);
                const idsDisable = await axios.post(getApiUrl(window.location.origin)+disableids,data);
                // const idsDisable = await axiosPost(getApiUrl(window.location.origin)+disableids, data, source2.token);
                respuesta.data.map((option) => {               
                    if( idsDisable.data.includes(option.value) ){
                        option["isDisabled"] = true;
                    }
                })

                saveOptions(respuesta.data);
                if(typeof saveBackUpOptions === 'function'){
                    saveBackUpOptions(respuesta.data);
                }
                savedOptions = true;
            }

            if( disableidsarray != null &&  disableidsarray != "" ){                  
                respuesta.data.map((option) => {               
                    if( disableidsarray.includes(option.value) ){
                        option["isDisabled"] = true;
                    }
                })

                saveOptions(respuesta.data);
                if(typeof saveBackUpOptions === 'function'){
                    saveBackUpOptions(respuesta.data);
                }
                savedOptions = true;
            }

            // Si queremos filtrar las opciones en función del valor de otro control.
            if( depends.control != 0 ){
                const data = new FormData();
                const validacion = apiKey();
                data.append("validacion", validacion);
                data.append("value", formulario[depends.control]);
                const respuesta = await axios.post(getApiUrl(window.location.origin)+depends.action,data);
                // const respuesta = await axiosPost(getApiUrl(window.location.origin)+depends.action, data, source3.token);

                saveOptions(respuesta.data);
                if(typeof saveBackUpOptions === 'function'){
                    saveBackUpOptions(respuesta.data);
                }
                savedOptions = true;
            }

            if( !savedOptions ){
                saveOptions(respuesta.data);
                if(typeof saveBackUpOptions === 'function'){
                    saveBackUpOptions(respuesta.data);
                }   
            }
        }
    }
    
    const handleFiltro = async (e) => {
        
        if(e.target.classList.contains("filtrue")){
            // Filtro activado, botón verde, sql CON WHERE
            await getDataCbo(dataType, data2);
        }
        if(e.target.classList.contains("filfalse")){
            // Filtro desactivado(mostramos todo), botón rojo, sql SIN WHERE
            await getDataCbo(dataType, data);            
        }
        saveFiltrandoUbi(!filtrandoUbi)
    }

    const handleFilCen = async (param) => {
        if(param){
            saveBtnFilCen("todos");
        }else{
            saveBtnFilCen("filtrando");
        }
        clickBtnFilCen(param);
    }
      
    const Select = props => (
        <FixRequiredSelect
          {...props}
          SelectComponent={BaseSelect}
        //   options={props.options || options}
        />
    );

    // Si solo hay un informe lo seleccionamos
    if(options && options.length == 1 && saveValSelect && selectDirectIfOneOption == true){
        if(options[0].isDisabled != true){
            saveValSelect(options[0].value);
        }
    }

    if( modifyOptions ){    
        auxOptions = modifyOptions(options);
        // Si solo hay un informe lo seleccionamos
        if(auxOptions && auxOptions.length == 1 && saveValSelect && selectDirectIfOneOption == true){
            if(auxOptions[0].isDisabled != true){
                saveValSelect(auxOptions[0].value);
            }
        }       
    }

    const getTxtOption = (options) => {
        const option = options.filter(option => option.value == val);
        const label = option[0] ? option[0].label : '';
        return label;

    }

      return(        
            modifyOptions
            ?
                <div className={"form-group col-md-"+col}>          
                        {
                            label ? <label htmlFor={id}>{label}</label> : null
                        }
                        {
                            btnfilubi && getDecodedCookie('nivel') <= 2 
                            ? 
                                <i className="fal fa-question-circle"></i> 
                            : 
                                null
                        }
                        {
                            setTxtMode == true
                            ?
                                <p>{auxOptions.length > 0 ? auxOptions.filter(option => option.value == val).label: ''}</p>
                            :
                                <Select 
                                        // Este select de ubicaciones del modal de dividir o añadir materiales del wizard de materiales
                                        options={auxOptions}
                                        name={name}             
                                        id={id}
                                        placeholder={ placeholder } 
                                        // value={options.filter(option => option.value == val)}
                                        value={auxOptions.length > 0 ? auxOptions.filter(option => option.value == val) : null}
                                        onChange={ change }
                                        required={required}
                                        // isDisabled={ estadoForm === "disabled" || estadoForm === "readonly" || readOnly ? true :  disabled}
                                        isDisabled={ estadoForm === "disabled" || estadoForm === "readonly" ? true :  disabled}
                                        // isClearable={isClearable}
                                        isClearable={readOnly === "readonly" ? false : isClearable}
                                        isSearchable={isSearchable}
                                        isMulti={false}
                                        isLoading={loading || loadingSelect ? true : false}
                                        components={animatedComponents}    
                                        noOptionsMessage={ ({ inputValue: string }) => confApp.config.trad.components.select.noresult.title } 
                                        styles={customStyles}   
                                        menuIsOpen={readOnly === "readonly" || estadoForm === "readonly" || loading || loadingSelect ? false : undefined}
                                        isReadOnly={readOnly}
                                        menuPortalTarget={document.body}                                              
                                        menuPlacement={"auto"}
                                        formatOptionLabel={
                                            !selectinformes ?
                                                function(data) {
                                                    return (
                                                        <span dangerouslySetInnerHTML={{ __html: data.label }} />
                                                    );
                                                }
                                            :
                                                null
                                        }
                                />                                     
                        }
                </div>
            :
                <div className={"form-group col-md-"+col}>          
                        {
                            label ? <label htmlFor={id}>{label}</label> : null
                        }
                        {
                            btnfilubi 
                            ?                             
                                <i 
                                    className={filtrandoUbi ? "ml-2 fal fa-map-marker-alt btn-success text-center filtrue": "ml-2 fa-light  fal fa-map-marker-alt-slash btn-danger text-center filfalse" }
                                    onClick={ e => handleFiltro(e) }
                                    style={{ minWidth: "52px", fontSize: "25px", padding: "5px 10px", borderRadius: "5px"}}
                                    >
                                </i>   
                            : 
                                null
                        }
                        {
                           btnFilCen == "filtrando"
                            ?                             
                                <span 
                                    className={"ml-2 btn-success text-center"}
                                    onClick={ e => handleFilCen(true) }
                                    style={{ padding: "5px 10px", borderRadius: "5px"}}
                                    >
                                        Mostrar todos los agentes
                                </span>   
                            : 
                            btnFilCen == "todos"
                            ?
                                <span 
                                    className={"ml-2 btn-danger text-center"}
                                    onClick={ e => handleFilCen(false) }
                                    style={{ padding: "5px 10px", borderRadius: "5px"}}
                                    >
                                        Filtrar agentes
                                </span>
                            :
                                null
                        }
                        {
                            setTxtMode == true
                            ?
                                <p>{getTxtOption(options)}</p>
                            :
                                <Select 
                                        options={options}
                                        name={name}             
                                        id={id}
                                        placeholder={ placeholder } 
                                        // value={options.filter(option => option.value == val)}
                                        value={options.length > 0 ? options.filter(option => option.value == val) : null}
                                        onChange={ change }
                                        required={required}
                                        // isDisabled={ estadoForm === "disabled" || estadoForm === "readonly" || readOnly ? true :  disabled}
                                        isDisabled={ estadoForm === "disabled" || estadoForm === "readonly" || disabled === true ? true :  disabled}
                                        // isClearable={isClearable}
                                        isClearable={readOnly === "readonly" ? false : isClearable}
                                        isSearchable={isSearchable}
                                        isMulti={false}
                                        isLoading={loading || loadingSelect ? true : false}
                                        components={animatedComponents}    
                                        noOptionsMessage={ ({ inputValue: string }) => confApp.config.trad.components.select.noresult.title } 
                                        styles={customStyles}   
                                        menuIsOpen={readOnly === "readonly" || readOnly === true || estadoForm === "readonly" || loading || loadingSelect ? false : undefined}
                                        isReadOnly={readOnly}
                                        menuPortalTarget={document.body}                                              
                                        menuPlacement={"auto"}
                                        formatOptionLabel={
                                            !selectinformes ?
                                                function(data) {
                                                    return (
                                                        <span dangerouslySetInnerHTML={{ __html: data.label }} />
                                                    );
                                                }
                                            :
                                                null
                                        }
                                />            
                        }
                </div>       
    )
}

export default MiSelect;