import React, { useEffect, useContext, useState } from 'react';
import $ from 'jquery';
import DataTable from 'datatables.net';
import { LoginContext } from '../../hooks/context/LoginContext';
import axios from 'axios';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTable from '../../componentes/ui/MiDataTable';
import MiDataTableEdiRap from './MiDataTableEdiRap';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';
import Kpi from '../../componentes/ui/Kpi';
import Button from '../../componentes/ui/Button';
import ButtonOutline from '../../componentes/ui/ButtonOutline';
import MiSelect from '../../componentes/formulario/MiSelect';
import FormInput from '../../componentes/formulario/FormInput';
import { Spinner } from 'react-bootstrap';
import swal from 'sweetalert';

import { alertService } from '../../services/alertservice';
import { Alert } from '../../componentes/ui/Alert';

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { getApiUrl, axiosPost, MiReplaceAll, getAhoraddmmyyyy, getPantDocFromNivel, setStockOptionsSelectUbi, getStockOptionsSelectUbi, getTitle, encryptData, getDecodedCookie } from '../../helpers/helperApp';

import { Fragment } from 'react';
import FormRow from '../../componentes/formulario/FormRow';
import { NavLink } from 'react-router-dom';

$.DataTable = DataTable;
let backUpOptionsUbi;
const url = window.location;

function Edirap(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp, apiKey, appCryptCom } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ documentos, saveDocumentos ] = useState([])
    const [ lineas, saveLineas ] = useState(null);
    const [ loadingCalculate, saveLoadingCalculate ] = useState(false);
    const [ optionsUbi, saveOptionsUbi ] = useState(null);
    const [ optionsUbiAddForm, saveOptionsUbiAddForm ] = useState(null);
    const [ loadingSelectUbiAddForm, saveLoadingSelectUbiAddForm ] = useState(false);
    const [ loadedSelect, saveLoadedSelect ] = useState(false);
    const [ optionsUni, saveOptionsUni ] = useState(null);
    const [ idFiltro, saveIdFiltro ] = useState(0);
    const [ idParam, saveIdParam ] = useState(null);
    const [ infoDoc, saveInfoDoc ] = useState("");
    const [ idDocActual, saveIdDocActual ] = useState(null);
    const [ idDocActualTabla, saveIdDocActualTabla ] = useState(null);
    const [ unidad, saveUnidad ] = useState(0);
    const [ cantidad, saveCantidad ] = useState(0);
    const [ ubicacion, saveUbicacion ] = useState(0);
    const [ reloadKpi, saveReloadKpi ] = useState(0);

    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, adjuntosPrin, saveAdjuntosPrin, adjuntosExistentesPrin, saveAdjuntosExistentesPrin, registrosHijo,  formularioHijo, saveFormularioHijo, adjuntosHijo, saveAdjuntosHijo, adjuntosExistentesHijo, saveAdjuntosExistentesHijo, handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, saveLoadReg, regIniciales, handleEditRegHijo, handleDeleteItemPrin, handleDeleteItemHijo, handleSubmitPrin, handleSubmitHijo, resetFormPrin, resetFormHijo, handleSelectFormPrin, handleSelectFormHijo, handleSelectFormPrinAndSetValue, handleSelectFormHijoAndSetValue, handleClonePrin, handleCloneHijo } = useLogicForm();

    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{ 
                    document.title = getTitle(confPantalla.config.trad, confPantalla.edirap.title);                    
                    if( respuesta == 1 ){
                        saveTieneNivel(true);
                        // Seleccionar los parámetros que nos llegan por la url
                        if( props.location.state ){
                            if( "iddoc" in props.location.state ){
                                // Nos llega el documento como parámetro                                    
                                saveIdParam(props.location.state.iddoc);   
                                handleGetLineas(props.location.state.iddoc);   
                            }                               
                        }                                       
                        initOptionsSelectUbiAddForm();          
                    }
                    else if( respuesta == 0 ){
                        saveTieneNivel(false);
                    }                    
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": getDecodedCookie('id'), "tabla": "undefined" })
            }
                document.querySelector("body").classList.remove("sidebar-open");

                // Token cancelación axios al desmontar componentes
                source.cancel();
        } 

    },[confPantalla])


    const initOptionsSelectUbiAddForm = async() => {

        // Combo ubicaciones para la tabla de edicion
        const ubi = confPantalla.edirap.controles.ubi;
        await loadOptionsCbo(ubi.datatype,ubi.data, optionsUbiAddForm, saveOptionsUbiAddForm, true);
    }



    const loadOptionsCbo = async (fuente, opcion, options, saveOptions, backUp=false) =>{
        const data = new FormData();
        data.append("validacion", apiKey());                        
        
        const idCookie = await encryptData(cookies.get('id'), appCryptCom);
        data.append('ua', idCookie);
        const nivelCookie = await encryptData(cookies.get('nivel'), appCryptCom);
        data.append('na', nivelCookie);
                    
        // Encriptamos el tag sql. 
        opcion = await encryptData(opcion, appCryptCom);
        data.append(fuente, opcion);                

        if( options == null ){
            let url = null;
            if( fuente == "filter" ){
                url = getApiUrl(window.location.origin)+'/filters/getFilter';
            }else{
                url = getApiUrl(window.location.origin)+'/app/getDataCbo';
            }
            const respuesta = await axios.post(url, data);
            saveOptions(respuesta.data);            
            if(backUp){
                backUpOptionsUbi = respuesta.data;
            }
                
        }

        saveLoadedSelect(true);
    }



    const handleGetLineas = async(param=null) => {

        // Obtenemos los option para las ubicaciones para los combos de la tabla con las líneas
        await loadOptionsCbo(confPantalla.edirap.controles.idubi.datatype, confPantalla.edirap.controles.idubi.data, optionsUbi, saveOptionsUbi);
        // Obtenemos los option para los articulos para los comobos de la tabla de las líneas
        await loadOptionsCbo(confPantalla.edirap.controles.iduni.datatype, confPantalla.edirap.controles.iduni.data, optionsUni, saveOptionsUni);        
        
        let table, rows, idDoc;
        if( param ){
            saveLineas([])
            saveLoadingCalculate(true);
            idDoc = param;
            saveIdDocActual(idDoc);
            saveIdDocActualTabla(idDoc);
            const data = new FormData();      
            data.append("validacion", apiKey());
            data.append("iddoc", JSON.stringify(idDoc));
            // const respuesta = await axios.post(getApiUrl(window.location.origin)+confPantalla.edirap.controles.datatableres.getdata,data);
            const respuesta = await axiosPost(getApiUrl(window.location.origin)+confPantalla.edirap.controles.datatableres.getdata, data, source.token);

            // Reseteamos la tabla de resultado
            // saveLineas(null);
            if( typeof respuesta.data !== "string" ){
                saveLineas(respuesta.data);
                // if( respuesta.data.length > 0 ){                    
                //     saveIdFiltro(respuesta.data[0].tipdoc);
                // }
                
                table = $("#edirap").DataTable();
                rows = table.rows().data();
                for(let i=0; i < rows.length; i++){
                    if( rows[i].id == idDoc ){
                        table.row(':eq('+i+')', { page: 'current' }).select();
                        saveInfoDoc(rows[i]);
                    }
                }
            }else if( typeof respuesta.data === "string" ){            
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true});            
            } 
        }else{
            table = $("#edirap").DataTable();
            rows = table.rows( { selected: true } ).data();
            if( rows.length > 0 ){
                saveLineas([])
                saveLoadingCalculate(true);
                idDoc = rows[0].id;
                saveIdDocActual(idDoc);
                saveIdDocActualTabla(idDoc);
                saveInfoDoc(rows[0]);
                const data = new FormData();      
                data.append("validacion", apiKey());
                data.append("iddoc", JSON.stringify(idDoc));
                // const respuesta = await axios.post(getApiUrl(window.location.origin)+confPantalla.edirap.controles.datatableres.getdata,data);
                const respuesta = await axiosPost(getApiUrl(window.location.origin)+confPantalla.edirap.controles.datatableres.getdata, data, source.token);
                // if( respuesta.data.length > 0 ){
                //     saveIdFiltro(respuesta.data[0].tipdoc);
                // }

                // Reseteamos la tabla de resultado
                // saveLineas(null);
                if( typeof respuesta.data !== "string" ){
                    saveLineas(respuesta.data);
                }else if( typeof respuesta.data === "string" ){            
                    alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true});            
                } 
    
            }else{
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confPantalla.edirap.controles.emptyclick.title}`,{autoClose: true});
                
            }  
        }

        saveLoadingCalculate(false);
    }

    const activarModoCreacion = (e, rowData) => {
      
        setTimeout((reset)=>{
            let table = $("#edirap").DataTable();
            let rows = table.rows( { selected: true } ).data();
            if( rows.length == 0 ){
                // No hay líneas seleccionadas. Reseteamos.
                saveLineas(null)
                saveUnidad(0);
                saveCantidad(0);
                saveUbicacion(0);
                saveIdDocActual(null);
            }
        },500)

        saveIdDocActualTabla(null);
        
    }

    const addLinea = () => {
        if( unidad != 0 ){
            if( !idFiltro && !idDocActual ){
                // Error. Debe seleccionar un tipo de documento
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confPantalla.edirap.errortipdoc.title}`, {autoClose: false});
            }else if(idFiltro && !idDocActual){
                // Crear documento     
                saveLoadReg(true);       
                let tipo = $('#kpiTipDoc').find(':selected');
                tipo = tipo.prevObject[0].outerText;
                let ahora = getAhoraddmmyyyy()
                let text = MiReplaceAll(confPantalla.edirap.swal.text.title, "${tipo}", tipo);
                text = MiReplaceAll(text, "${ahora}", ahora);
                swal({
                    title: confPantalla.edirap.swal.title,
                    text: "",
                    content: {
                        element : "div",
                        attributes : {
                            innerHTML : `
                                        <h5>${confPantalla.edirap.swal.text.title} ${tipo}</h5>
                                        <h5>${confPantalla.edirap.swal.text2.title} ${ahora}</h5>
                                        `,
                            style : "max-width:fit-content, text-align: center"
                        }
                    },               
                    icon: "warning",
                    buttons: {
                        cancel : {text: confPantalla.edirap.swal.btncancel.title, className:'btn btn-flat mr-1 btn-outline-secondary', visible: true},
                        confirm : {text: confPantalla.edirap.swal.btnconfirm.title, className:'btn btn-flat mr-1 btn-outline-success btnSwalSuccess'},
                    } 
                }).then(confirmacion => {
                    if( confirmacion == true ){
                        accionesDoc(idDocActual, idFiltro, unidad, cantidad, ubicacion, "crear")                    
                    }else{
                        saveLoadReg(false);
                    }
                })                    
            }else{
                saveLoadReg(true);       
                // Editar documento
                accionesDoc(idDocActual, idFiltro, unidad, cantidad, ubicacion, "modificar") 
            }
        }else{
            // Error. Debe seleccionar una unidad            
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confPantalla.edirap.errorunidad.title}`, {autoClose: false});
        }
    }

    const accionesDoc = async (idDocActual, idFiltro, unidad, cantidad, ubicacion, accion) => {
        const data = new FormData();
        data.append("validacion", apiKey());
        data.append("iddoc", idDocActual); 
        data.append("tipdoc", idFiltro); 
        data.append("iduni", unidad); 
        data.append("num1", cantidad); 
        data.append("idubi", ubicacion);
        data.append("accion", accion)                    
        const respuesta = await axios.post(getApiUrl(window.location.origin)+'/edirap/actionsedirap', data);
        saveLoadReg(false);       
        if( typeof respuesta.data !== "string" ){
            let mensaje = ""
            if( accion == "crear"  ){
                mensaje = confPantalla.edirap.docsuccess.title;
            }else{
                mensaje = confPantalla.edirap.linesuccess.title;
            }
            saveUnidad(0);
            saveCantidad(0);
            saveUbicacion(0);
            handleGetLineas(respuesta.data);            
            saveReloadKpi(reloadKpi+1);            
            alertService.success(`<i class="fal fa-check-circle mr-1"></i>${mensaje}`, {autoClose: true}); 
        }else if( typeof respuesta.data === "string" ){            
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true});            
        } 
    }

    const handleSelectUnidad = async (selectedItem, nameOfComponent) => {
        // Añadimos la cantidad a los option del select de ubicaciones del formulario de añadir
        let iduni = selectedItem ? selectedItem.value : 0;
        const respuesta = await getStockOptionsSelectUbi(backUpOptionsUbi, iduni, saveLoadingSelectUbiAddForm);
        saveOptionsUbiAddForm(respuesta);

        if( selectedItem ){
            saveUnidad(selectedItem.value) 
        }else{
            saveUnidad(0)
        }
    }
    const handleSelectUbicacion = (selectedItem, nameOfComponent) => {

        if( selectedItem ){
            saveUbicacion(selectedItem.value) 
        }else{
            saveUbicacion(0)
        }
    }
    const handleCantidad = (e) => {
        const { name, value } = e.target;
        saveCantidad(value)        
    }

    
    return(
        <div className="App wrapper">
            <div>
                {   loadReg ?
                    <div className="alertFullWhite">
                        <Spinner animation="border" />
                    </div> 
                    :
                    null
                }
            </div>   
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Alert fondo={true} />
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
            null
        } 
        <div className="content-wrapper">
            <div className="content-header">
            </div>
            <section className="content">                
                {
                    !tieneNivel 
                    ?
                        <PermissionRefused
                            traduction={confPantalla.config.trad.components.permissionrefused} 
                        />
                    :
                    <Fragment>
                    {
                        loadedConf === true && confPantalla !== undefined
                        ?
                            <Section
                                titulo={ 
                                    idDocActual == null
                                    ?
                                        confPantalla.edirap.section.titulo
                                    :
                                        getDecodedCookie('idioma').toLowerCase() == "es".toLowerCase() ?
                                            infoDoc.tipdoccod+" - "+infoDoc.tipdocnom+" - "+infoDoc.nom1
                                        :
                                            getDecodedCookie('idioma').toLowerCase() == "en".toLowerCase() ?
                                                infoDoc.tipdoccod+" - "+infoDoc.tipdocnom+" - "+infoDoc.nom1_en
                                            :
                                                infoDoc.tipdoccod+" - "+infoDoc.tipdocnom+" - "+infoDoc.nom1+" - "+infoDoc.nom1_en
                                }  
                                icono={ confPantalla.edirap.section.icono } 
                                col={ confPantalla.edirap.section.col } 
                                idPrin={ idDocActual } 
                                borderHeader={ idDocActual != null ? infoDoc.color : null }
                                recurrencia={ idDocActual != null ? infoDoc.recurrencia : null}
                            >

                                <Kpi
                                    conf={ confPantalla.edirap.controles.kpi }
                                    getDataTable1 = { confPantalla.edirap.controles.datatable.getdata }
                                    saveDataTable1 = { saveDocumentos }                           
                                    saveLoadingTable = { saveLoadingTable }
                                    filtro={ confPantalla.edirap.controles.kpi.tipdoc }
                                    idFiltro={ idFiltro }
                                    saveIdFiltro={ saveIdFiltro }
                                    idParam1={ idParam }
                                    saveIdParam1={ saveIdParam }
                                    noDisplay={ idParam != null ? true : false }
                                    forceReload={props.location.state ? null : reloadKpi}
                                />

                                <MiDataTable 
                                    estadoForm= { "" }
                                    formulario={ {id:0} }
                                    id={"edirap"}
                                    columnas={confPantalla.edirap.controles.datatable.columns}
                                    data={documentos}
                                    buttons={confPantalla.edirap.controles.datatable.buttons}
                                    ordering={confPantalla.edirap.controles.datatable.ordering}
                                    order={confPantalla.edirap.controles.datatable.order}
                                    searching={confPantalla.edirap.controles.datatable.searching}
                                    paging={confPantalla.edirap.controles.datatable.paging}
                                    select={confPantalla.edirap.controles.datatable.select}
                                    pagelength={confPantalla.edirap.controles.datatable.pagelength}
                                    // saveLoadingTable={ saveLoadingTable }
                                    loadingTable={loadingTable}
                                    columnactions={confPantalla.edirap.controles.datatable.columnactions.title}
                                    linkColumns={confPantalla.edirap.controles.datatable.linkColumns}
                                    traduction={confPantalla.config.trad.components.datatable}
                                    functionClickTd={ idParam == null ? activarModoCreacion : null }
                                    noDisplay={ idParam != null ? true : false }
                                    selectId={ {id:idDocActualTabla}}                                         
                                />                                
                                {
                                    documentos.length > 0
                                    ?
                                        <Button 
                                            clases={ "col-12 mt-2 mb-4" }
                                            estadoForm={ props.location.state ? "disabled" : "" }
                                            color={ "success" }  
                                            type="button" 
                                            icono={ "" }   
                                            id="btngetart"     
                                            texto={ confPantalla.edirap.controles.btncalcular.title }   
                                            accion={ handleGetLineas } 
                                            disabled={ false }
                                        /> 
                                    :
                                        null
                                }
                                {
                                    lineas != null && infoDoc
                                    ?
                                        <NavLink to={getPantDocFromNivel(getDecodedCookie('nivel'))+"/"+idDocActual}>
                                            <hr data-content={infoDoc.equipo+" - "+infoDoc.descripcion} className="hr-titleinfwhite"></hr>
                                        </NavLink>                                               
                                    :

                                        null
                                }
                                {/* {
                                    documentos.length > 0
                                    ? */}
                                        <FormRow clases={"formedirap"}>                                            
                                            <h4 className='col-12'>{idDocActual == null ? confPantalla.edirap.modocreacion.title :  confPantalla.edirap.modoedicion.title}</h4>
                                            <MiSelect  
                                                estadoForm=  { "" }
                                                val=         { unidad  }   
                                                loading=     { loadReg ? true : false }
                                                id=          { confPantalla.edirap.controles.iduni.id }    
                                                name=        { confPantalla.edirap.controles.iduni.name }   
                                                label=       { confPantalla.edirap.controles.iduni.label }    
                                                placeholder= { confPantalla.edirap.controles.iduni.placeholder }
                                                col=         { confPantalla.edirap.controles.iduni.col } 
                                                isClearable= { confPantalla.edirap.controles.iduni.isclearable }
                                                isSearchable={ confPantalla.edirap.controles.iduni.issearchable }
                                                dataType=    { confPantalla.edirap.controles.iduni.datatype }
                                                data=        { confPantalla.edirap.controles.iduni.data }
                                                required=    { confPantalla.edirap.controles.iduni.required } 
                                                disabled=  { confPantalla.edirap.controles.iduni.disabled }
                                                readOnly =   { confPantalla.edirap.controles.iduni.readonly }
                                                disablethisid={confPantalla.edirap.controles.iduni.disablethisid}
                                                disableids={confPantalla.edirap.controles.iduni.disableids}
                                                formulario= { {id:0} }   
                                                change= { handleSelectUnidad }

                                            />
                                            <FormInput 
                                                estadoForm= { "" }
                                                value=      { cantidad }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.edirap.controles.num1.id }   
                                                type=       { confPantalla.edirap.controles.num1.type }  
                                                name=       { confPantalla.edirap.controles.num1.name }   
                                                label=      { confPantalla.edirap.controles.num1.label }                                                       
                                                placeholder={ confPantalla.edirap.controles.num1.placeholder }    
                                                col=        { confPantalla.edirap.controles.num1.col } 
                                                required=   { confPantalla.edirap.controles.num1.required }
                                                readonly=   { confPantalla.edirap.controles.num1.readonly }                                                
                                                disabled=   { confPantalla.edirap.controles.num1.disabled }
                                                alignRight= { confPantalla.edirap.controles.num1.alignRight }
                                                change=     { handleCantidad }
                                                onBlur=     {null} 
                                            />
                                            {
                                                loadedSelect
                                                ?
                                                    <MiSelect  
                                                        estadoForm=  { "" }
                                                        val=         { ubicacion }   
                                                        loading=     { loadReg  || loadingSelectUbiAddForm ? true : false }
                                                        id=          { confPantalla.edirap.controles.ubi.id }    
                                                        name=        { confPantalla.edirap.controles.ubi.name }   
                                                        label=       { confPantalla.edirap.controles.ubi.label }    
                                                        placeholder= { confPantalla.edirap.controles.ubi.placeholder }
                                                        col=         { confPantalla.edirap.controles.ubi.col } 
                                                        isClearable= { confPantalla.edirap.controles.ubi.isclearable }
                                                        isSearchable={ confPantalla.edirap.controles.ubi.issearchable }
                                                        // dataType=    { confPantalla.edirap.controles.ubi.datatype }
                                                        // data=        { confPantalla.edirap.controles.ubi.data }                                                
                                                        dataType=    { "codigo" }
                                                        data=        { optionsUbiAddForm }
                                                        required=    { confPantalla.edirap.controles.ubi.required } 
                                                        disabled=  { confPantalla.edirap.controles.ubi.disabled }
                                                        readOnly =   { confPantalla.edirap.controles.ubi.readonly }
                                                        disablethisid={confPantalla.edirap.controles.ubi.disablethisid}
                                                        disableids={confPantalla.edirap.controles.ubi.disableids}
                                                        depends={ confPantalla.edirap.controles.ubi.depends }
                                                        formulario= { {id:0} }   
                                                        change= { handleSelectUbicacion }
                                                        modifyOptions = { setStockOptionsSelectUbi }
                                                    />
                                                :
                                                    null
                                            }
                                            <button 
                                                style={{marginTop: "32px"}} 
                                                id="btnaddline"
                                                className={"btn btn-flat btn-outline-success col-md-2 col-sm-12 mb-4" } 
                                                type="button" 
                                                onClick={ addLinea } 
                                                disabled={ false }
                                            >
                                                <i className="fal fa-plus"></i>
                                                {confPantalla.edirap.controles.btnaddline.title}
                                            </button>                                         
                                        </FormRow>                           

                                    {/* :
                                        null
                                }                     */}
                                
                                {
                                    lineas != null
                                    ?
                                        <MiDataTableEdiRap 
                                            estadoForm= { "" }
                                            formulario={ {id:0} }
                                            id={"lineas"}
                                            columnas={confPantalla.edirap.controles.datatableres.columns}
                                            data={lineas}
                                            saveData={saveLineas}
                                            buttons={confPantalla.edirap.controles.datatableres.buttons}
                                            ordering={confPantalla.edirap.controles.datatableres.ordering}
                                            order={confPantalla.edirap.controles.datatableres.order}
                                            searching={confPantalla.edirap.controles.datatableres.searching}
                                            paging={confPantalla.edirap.controles.datatableres.paging}
                                            select={confPantalla.edirap.controles.datatableres.select}
                                            pagelength={confPantalla.edirap.controles.datatableres.pagelength}
                                            saveLoadingTable={ saveLoadingCalculate }
                                            loadingTable={loadingCalculate}
                                            columnactions={confPantalla.edirap.controles.datatableres.columnactions.title}
                                            linkColumns={confPantalla.edirap.controles.datatableres.linkColumns}
                                            traduction={confPantalla.config.trad.components.datatable}
                                            selectUbi={confPantalla.edirap.controles.idubi}
                                            selectUni={confPantalla.edirap.controles.iduni}
                                            inputNum1={confPantalla.edirap.controles.num1}
                                            swaldelete={confPantalla.edirap.swaldelete}  
                                            optionSelectUbi={optionsUbi} 
                                            optionSelectUni={optionsUni}
                                            idFiltro={idFiltro}                                            
                                        />        
                                    :

                                        null

                                }
                                
                            </Section>
                        :
                            <LoadingPage />
                        }
                    </Fragment>
                }
                
            </section>
        </div>
        <Footer />        
        </div>

    );

}
export default Edirap